main {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
    background: #555;
}

.slick-prev:before,
.slick-next:before {
    color: #191730;
}

.slick-dots li.slick-active button:before {
    color: #191730;
}

@media (max-width: 768px) {
    #logoHeader {
        height: 25px;
    }
    #logoFooter {
        height: 110px;
    }
    #imgFooter {
        height: 0;
    }
    #responsiveImageInfo {
        width: 0;
        margin: 0;
        padding: 0;
    }
    #responsiveImageTabs {
        width: 70px;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .slick-list {
        width: 100%;
        margin-left: 20px;
    }
    .slick-slide > div {
        padding: 0 5px;
    }
}

@media (min-width: 769px) {
    #logoHeader {
        height: 40px;
    }
    #logoFooter {
        height: 150px;
    }
    #imgFooter {
        height: 200px;
    }
    #responsiveImageInfo {
        width: 500px !important;
        margin: 50px;
        padding: 0;
        box-sizing: border-box;
    }
    #responsiveImageTabs {
        width: 200px !important;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .slick-list {
        margin: 0 -5px;
    }
    .slick-slide > div {
        padding: 0 5px;
    }
}
